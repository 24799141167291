<template>
  <v-container class="py-0 resultado-triagem" style="height: 140vh;background: #7E7CFF" fluid >
    <BackArrow/>
      <v-row align="start" style="padding-bottom: 0;" class="resultado-triagem">
        <v-col cols="12">
          <generic-btn
              class="px-6"
            :btn-props="{ icon: true }"
            :on-click="() => $router.push({name: 'miniHomeCovid'})"
          >
          </generic-btn>
        </v-col>
      </v-row>

      <v-row align="center" justify="center" >
        <v-col cols="12">
              <v-row class="mt-0">
                <v-col cols="12">
                  <p class="text-center generic-title triagem-title">
                    {{ result.resultInterval ? result.resultInterval.description : ''}}
                  </p>
                </v-col>

                <v-col cols="12" class="pb-0">
                  <p class="text--white main-text text--white px-4 mb-0" style="color: white" v-html="result.resultInterval ? result.resultInterval.template : ''">
                  </p>
                </v-col>

                <v-col class="text-center" style="padding-bottom: 0" cols="12" v-if="!screeningSurvey.release">
                  <v-btn
                          rounded
                          class="blue--text py-3 px-15"
                          style="background-color: #E9EBFD; font-weight: bold; text-transform:none !important; font-weight: bolder; font-size: 12px;"
                          dark
                          x-small
                          @click="covidGuidance(screeningSurvey)"
                  >
                    <span style="color: blue !important;">Ver orientações</span>
                  </v-btn>
                </v-col>

                <v-col cols="12" class="py-5 my-5">
                  <p class="text-center generic-title mb-0">
                    <img v-if="screeningSurvey.release"
                            class="mt-10 icon-triagem"
                            style=""
                            src="/img/icons/commons/checkmark.svg"
                    />
                    <img  v-else
                          class="icon-triagem"
                          src="/img/icons/commons/alert-triangle.svg"
                    />
                  </p>
                </v-col>

                <v-col cols="12">
                  <p class="text-center generic-title mb-0 data-triagem ">
                    {{ screeningSurvey.generatedDateFormatted }}
                  </p>
                </v-col>
                <v-col cols="12" class="mt-0 pt-0">
                  <p style="color: white" class="text-center main-text ma-0">
                    {{ $auth.user().name }}
                  </p>
                </v-col>
              </v-row>
              <v-row class="py-0 my-0">
                <v-col cols="12">
                  <GenericBtn
                    :btn-props=" {
                      large: true,
                      position: 'center',
                      block: true,
                      large: true,
                  } "
                    :color="color"
                    :on-click="() => (qrCode(screeningSurvey))"
                  >
                    Gerar QR code
                  </GenericBtn>
                </v-col>
              </v-row>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import EuProtegidoService from '../../services/euprotegido/EuProtegidoService';
import { quizResultsThemes } from "../../common/constants";
import store from "../../store/store";
import moment from "moment";
import BackArrow from "../../components/covid/back-arrow/index";
import GenericBtn from "../../components/generic-btn/index";
import { mapMutations } from 'vuex';

export default {
  name: 'finishScreening',
  data() {
    return {
      result: {},
      screeningSurvey: {},
      color: '',
      image: '',
      imageWidth: 0,
      imageTop: 0,
      marginCompensation: 0,
    }
  },
  components: {
    BackArrow,
    GenericBtn
  },
  methods: {
    ...mapMutations(['loading']),
    getResult() {
      let userAnswerSurvey = this.$route.params.userAnswerSurvey;
      this._euProtegidoService.SaveScreening(userAnswerSurvey)
              .then((response) => {
                this.screeningSurvey = response.data;
                let userAnswerSurvey = this.screeningSurvey.userAnswerSurvey;
                this.screeningSurvey.userName = this.$auth.user().name;
                this.screeningSurvey.generatedDateFormatted = moment(this.screeningSurvey.generatedDate).format('DD/MM/YYYY hh:mm');
                userAnswerSurvey ? this.loadUserAnswer(userAnswerSurvey) : this.loadAlertCase();
                this.setTheme();
                this.loading(false);
              })
              .catch(error => {
                store.commit('showmsg', {
                  text: "Erro ao salvar Triagem",
                  type: "error"
                });
              })
    },
    loadUserAnswer(userAnswerSurvey) {
      this.result = {
        pointAmount: userAnswerSurvey.totalQuestionViewed,
        rightAwnserAmount: userAnswerSurvey.rightAnswerAmount,
        resultInterval: {
          description: userAnswerSurvey.resultInterval ? userAnswerSurvey.resultInterval.description : '',
          template: userAnswerSurvey.processTemplate,
          theme: userAnswerSurvey.resultInterval ? userAnswerSurvey.resultInterval.theme : '',
        }
      }
    },
    loadAlertCase() {
      this.result = {
        pointAmount: 0,
        rightAwnserAmount: 0,
        resultInterval: {
          description: 'Ops, resultado indisponível.',
          template: 'Não foi possível carregar o resultado.',
          theme: quizResultsThemes.covidGood,
        }
      }
    },
    covidGuidance(screening){
      this.$router.push({ name: 'orientacoesCovid', params: { screeningId : screening.id } })
    },
    qrCode(screeningSurvey){
      this.$router.push({name: 'qrCodeTriagem',
        params: { userName : screeningSurvey.userName, generatedDateFormatted : screeningSurvey.generatedDateFormatted, exportData : screeningSurvey.exportData } })
    },
    setTheme() {
      let { resultInterval: { theme } } = this.result;
      let {
        covidBad, covidGood, defaultBad, defaultGood
      } = quizResultsThemes;

      let imageCovid = '/img/quiz/img_resultado_quiz_covid.png';
      let imageDefault = '/img/quiz/img_resultado_quiz_default.png'

      let green = '#84DF8D';
      let red = '#EA4965';

      if(theme === covidBad) {
        this.color = red;
        this.image = imageCovid;
        this.imageWidth = 250;
        this.imageTop = 1;
      }

      if(theme === covidGood) {
        this.color = green;
        this.image = imageCovid;
        this.imageWidth = 250;
        this.imageTop = 1;
      }

      if(theme === defaultGood) {
        this.color = green;
        this.image= imageDefault;
        this.imageWidth = 200;
        this.imageTop = 2.6;
        this.marginCompensation = 3.5;
      }

      if(theme === defaultBad) {
        this.color = red;
        this.image= imageDefault;
        this.imageWidth = 200;
        this.imageTop = 2.6;
        this.marginCompensation = 3.5;
      }
    }
  },
  beforeMount() {
    this.loading(true);
    this._euProtegidoService = new EuProtegidoService();
    moment.locale('pt-br');
    this.getResult();
  },
  created() {
  }
}
</script>

<style scoped>
  .triagem-title {
    margin-bottom: 0;
    color: white;
    font-size: 28px
  }

  .icon-triagem {
    height: 100px;
    width: 100px
  }

  .data-triagem {
    color: white;
    font-size: 26px
  }

  .resultado-triagem {
    background-color: #7E7CFF !important;
    background-image: url("../../../public/img/triagem/bg-resultado-triagem.svg") !important;
    background-size: cover !important;
    background-position: top center !important;
  }
</style>
